<template>
  <Notice level="warning">
    <h1 data-test="error-message-title" class="heading-card text-bold">
      {{ title }}
    </h1>
    <slot />
  </Notice>
</template>

<script>
import { Notice } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    Notice,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
