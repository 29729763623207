/* eslint-disable import/prefer-default-export */
export const get = (obj, path, defValue) => {
  if (!path) {
    return undefined;
  }
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj,
  );

  return result === undefined ? defValue : result;
};
