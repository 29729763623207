import Vue from 'vue';
import jump from 'jump.js';

Vue.directive('jump', {
  // When the bound element is inserted into the DOM...
  inserted(el, conds) {
    // Get href
    const href = el.getAttribute('href');
    const DURATION = 500;
    const OFFSET = 0;

    // Make sure target exists
    const target =
      href && href.startsWith('#') && href.length > 1
        ? document.querySelector(href)
        : null;

    // If not, return
    if (!target) {
      return;
    }

    try {
      // Add click listener
      el.addEventListener('click', () => {
        jump(target, {
          duration: DURATION,
          offset: conds.value || OFFSET,
          callback: undefined,
          a11y: true,
        });
      });
    } catch (e) {
      console.warn(e); // eslint-disable-line no-console
    }
  },
});
