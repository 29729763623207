import axios from 'axios';

export default class Api {
  constructor(options) {
    let baseURL = options.api;
    if (
      process.env.PREVIEW === 'true' ||
      process.env.DEPLOY_PREVIEW === 'true'
    ) {
      baseURL = 'https://find-a-course-preview.netlify.app/find/index-api';
    }

    const api = axios.create({
      baseURL,
    });

    api.interceptors.response.use(this.handleSuccess, this.handleError);
    this.api = api;
  }

  // eslint-disable-next-line class-methods-use-this
  handleSuccess(response) {
    return response;
  }

  instance() {
    return this.api;
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(error) {
    return Promise.reject(error);
  }

  get(route) {
    return this.api
      .get(route)
      .then((response) => response)
      .catch((err) => ({
        status: err.response.status,
        config: err.response.config,
        error: {
          message: `Unable to load data from ${this.api.defaults.baseURL}${route}. This error has been reported`,
        },
        data: err.response.data,
      }));
  }

  /**
   * Get a course / major offering by slug
   * @param  {String} offering slug
   * @return {Object} Offering
   */
  async getOffering(slug, type = 'course') {
    const endpoints = {
      course: 'courses',
      'short-course': 'short-courses',
      major: 'components',
      minor: 'components',
      specialisation: 'components',
      'honours-specialisation': 'honours-specialisation',
    };

    if (slug) {
      return this.get(`/${endpoints[type] || 'courses'}/${slug}.json`);
    }
    throw new Error('Error: not a valid offering');
  }

  /**
   * Get all offerings
   * @return {Array} list of offerings
   */
  async getAllCourses() {
    return this.get('/courses.json');
  }

  /**
   * Get an interest by slug
   * @param  {String} slug
   * @returns {Object} Interest
   */
  async getInterest(slug) {
    if (slug) {
      return this.get(`/interests/${slug}.json`);
    }
    throw new Error('Error: not a valid interest');
  }

  /**
   * @returns {Array} list of interests
   */
  async getAllInterests() {
    return this.get('/interests.json');
  }

  /**
   * Get a Short courses by slug
   * @param  {String} slug
   * @returns {Object} Short courses
   */
  async getShortCourse(slug) {
    if (slug) {
      return this.get(`/short-courses-and-microcredentials/${slug}.json`);
    }
    throw new Error('Error: not a valid short course');
  }

  /**
   * @returns {Array} list of short courses
   */
  async getAllShortCourses() {
    return this.get('/short-courses-and-microcredentials.json');
  }

  /**
   * @returns {Array} list of honours-specialisation
   */
  async getAllHonoursSpecialisation() {
    return this.get('/honours-specialisation.json');
  }

  /**
   * @returns {Array} list of components (majors, minors, specialisations)
   */
  async getAllComponents() {
    return this.get('/components.json');
  }
}
