const personas = [
  {
    id: 'secondary',
  },
  {
    id: 'undergrad',
  },
  {
    id: 'postgrad',
  },
  {
    id: 'parent',
  },
  {
    id: 'agent',
  },
  {
    id: 'teacher',
  },
  {
    id: 'other',
  },
];

module.exports = {
  personas,
};
