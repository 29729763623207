import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c550f2e8 = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _17ee8191 = () => interopDefault(import('../pages/find/pathways/index.vue' /* webpackChunkName: "pages/find/pathways/index" */))
const _3cebc550 = () => interopDefault(import('../pages/find/study-areas/index.vue' /* webpackChunkName: "pages/find/study-areas/index" */))
const _907340a0 = () => interopDefault(import('../pages/find/courses/honours-specialisation/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/index" */))
const _93e08330 = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug" */))
const _201dd6eb = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/index" */))
const _eacb0ae8 = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/structure.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/structure" */))
const _017ac1a8 = () => interopDefault(import('../pages/find/courses/honours-specialisation/_slug/_custom/index.vue' /* webpackChunkName: "pages/find/courses/honours-specialisation/_slug/_custom/index" */))
const _1f007938 = () => interopDefault(import('../pages/find/microcredentials/_slug.vue' /* webpackChunkName: "pages/find/microcredentials/_slug" */))
const _1637e049 = () => interopDefault(import('../pages/find/pathways/_slug.vue' /* webpackChunkName: "pages/find/pathways/_slug" */))
const _237967e8 = () => interopDefault(import('../pages/find/pathways/_slug/index.vue' /* webpackChunkName: "pages/find/pathways/_slug/index" */))
const _70836fed = () => interopDefault(import('../pages/find/short-courses/_slug.vue' /* webpackChunkName: "pages/find/short-courses/_slug" */))
const _22c1b83b = () => interopDefault(import('../pages/find/study-areas/_id/index.vue' /* webpackChunkName: "pages/find/study-areas/_id/index" */))
const _71e38a35 = () => interopDefault(import('../pages/find/courses/_component/_slug.vue' /* webpackChunkName: "pages/find/courses/_component/_slug" */))
const _5f0ad4f8 = () => interopDefault(import('../pages/find/courses/_component/_slug/index.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/index" */))
const _d8d7df14 = () => interopDefault(import('../pages/find/courses/_component/_slug/career-outcomes.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/career-outcomes" */))
const _e04aa956 = () => interopDefault(import('../pages/find/courses/_component/_slug/entry-requirements.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/entry-requirements" */))
const _2d268512 = () => interopDefault(import('../pages/find/courses/_component/_slug/fees.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/fees" */))
const _778b2643 = () => interopDefault(import('../pages/find/courses/_component/_slug/how-to-apply.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/how-to-apply" */))
const _6f8faa19 = () => interopDefault(import('../pages/find/courses/_component/_slug/structure.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/structure" */))
const _23968df4 = () => interopDefault(import('../pages/find/courses/_component/_slug/student-experience.vue' /* webpackChunkName: "pages/find/courses/_component/_slug/student-experience" */))
const _648d5341 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _39268110 = () => interopDefault(import('../modules/study-index/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/styleguide",
    component: _c550f2e8,
    name: "styleguide"
  }, {
    path: "/find/pathways",
    component: _17ee8191,
    name: "find-pathways"
  }, {
    path: "/find/study-areas",
    component: _3cebc550,
    name: "find-study-areas"
  }, {
    path: "/find/courses/honours-specialisation",
    component: _907340a0,
    name: "find-courses-honours-specialisation"
  }, {
    path: "/find/courses/honours-specialisation/:slug?",
    component: _93e08330,
    children: [{
      path: "",
      component: _201dd6eb,
      name: "find-courses-honours-specialisation-slug"
    }, {
      path: "structure",
      component: _eacb0ae8,
      name: "find-courses-honours-specialisation-slug-structure"
    }, {
      path: ":custom",
      component: _017ac1a8,
      name: "find-courses-honours-specialisation-slug-custom"
    }]
  }, {
    path: "/find/microcredentials/:slug?",
    component: _1f007938,
    name: "find-microcredentials-slug"
  }, {
    path: "/find/pathways/:slug",
    component: _1637e049,
    children: [{
      path: "",
      component: _237967e8,
      name: "find-pathways-slug"
    }]
  }, {
    path: "/find/short-courses/:slug?",
    component: _70836fed,
    name: "find-short-courses-slug"
  }, {
    path: "/find/study-areas/:id",
    component: _22c1b83b,
    name: "find-study-areas-id"
  }, {
    path: "/find/courses/:component?/:slug?",
    component: _71e38a35,
    children: [{
      path: "",
      component: _5f0ad4f8,
      name: "find-courses-component-slug"
    }, {
      path: "career-outcomes",
      component: _d8d7df14,
      name: "find-courses-component-slug-career-outcomes"
    }, {
      path: "entry-requirements",
      component: _e04aa956,
      name: "find-courses-component-slug-entry-requirements"
    }, {
      path: "fees",
      component: _2d268512,
      name: "find-courses-component-slug-fees"
    }, {
      path: "how-to-apply",
      component: _778b2643,
      name: "find-courses-component-slug-how-to-apply"
    }, {
      path: "structure",
      component: _6f8faa19,
      name: "find-courses-component-slug-structure"
    }, {
      path: "student-experience",
      component: _23968df4,
      name: "find-courses-component-slug-student-experience"
    }]
  }, {
    path: "/",
    component: _648d5341,
    name: "index"
  }, {
    path: "/find",
    component: _39268110,
    name: "study-index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
