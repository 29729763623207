import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import axiosRetry from 'axios-retry';
import { STATUS_200, STATUS_300 } from '~/constants/api';
import { TIMER_1000 } from '~/constants/timers';

class Api {
  constructor() {
    const baseURL = `${process.env.API_BASE}`;

    const defaultMaxReqPerSecond = 40;
    const maxReqPerSecond =
      parseInt(process.env.PAGE_BUILD_MAX_REQ_PS, 10) || defaultMaxReqPerSecond;
    const maxRetries = 3;

    const api = rateLimit(axios.create({ baseURL }), {
      maxRPS: maxReqPerSecond,
    });
    api.getMaxRPS();

    axiosRetry(api, {
      retries: maxRetries,
      retryDelay: (retryCount) => retryCount * TIMER_1000,
    });

    api.interceptors.response.use(this.handleSuccess, this.handleError);
    this.api = api;
  }

  // eslint-disable-next-line class-methods-use-this
  handleSuccess(response) {
    return response;
  }

  instance() {
    return this.api;
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(error) {
    return Promise.reject(error);
  }

  get(route, type = false) {
    this.getBase(type);
    return this.api
      .get(route)
      .then((response) => response)
      .catch((err) => ({
        status: err.response.status,
        config: err.response.config,
        error: {
          message: `Unable to load data from ${this.api.defaults.baseURL}${route} for type '${type}'. This error has been reported. Error: ${err.response.status}`,
        },
        data: err.response.data,
      }));
  }

  getBase(type) {
    switch (type) {
      case 'content':
        this.api.defaults.baseURL =
          'https://matrix-cms.unimelb.edu.au/study-dev/api/v2.0';
        break;
      case 'entry':
        this.api.defaults.baseURL = `${process.env.COURSE_SEARCH_API}/entry_requirements`;
        break;
      default:
        break;
    }
  }

  /**
   * Get a v2 offering by slug
   * @param  {String} slug of an offering
   * @returns {Object} course data which has been validated against offering schema and response status / error
   */
  async getOffering(slug) {
    const { data, status, error } = await this.get(
      `/v2/offerings/${slug}.json`,
    );

    if (status >= STATUS_200 && status < STATUS_300 && data) {
      return { data, status };
    }
    throw new Error(error.message);
  }

  /**
   * Get an interest by slug
   * @param  {String} slug
   * @returns {Object} Interest
   */
  async getInterest(slug) {
    if (slug) {
      return this.get(`/v2/interests/${slug}.json`);
    }
    throw new Error('Error: not a valid interest');
  }

  /**
   * Get a Pathway by slug
   * @param  {String} slug
   * @returns {Object} Pathway
   */
  async getPathway(slug) {
    if (slug) {
      return this.get(`/v2/pathways/${slug.toLowerCase()}.json`);
    }
    throw new Error('Error: not a valid pathway');
  }

  /**
   * Get a Short courses by slug
   * @param  {String} slug
   * @returns {Object} Short courses
   */
  async getShortCourses(slug) {
    if (slug) {
      return this.get(`/v2/short-courses/${slug.toLowerCase()}.json`);
    }
    throw new Error('Error: not a valid short course');
  }

  /**
   * Get a Micro credential by slug
   * @param  {String} slug
   * @returns {Object} Micro credentials
   */
  async getMicroCredentials(slug) {
    if (slug) {
      return this.get(`/v2/micro-certificates/${slug.toLowerCase()}.json`);
    }
    throw new Error('Error: not a valid micro certificate');
  }

  /**
   * Get a Honours Specialisation courses by slug
   * @param  {String} slug
   * @returns {Object} Honours Specialisation
   */
  async getHonoursSpecialisation(slug) {
    if (slug) {
      return this.get(`/v2/honours-specialisation/${slug}.json`);
    }
    throw new Error('Error: not a valid honours specialisation course');
  }
}

export default new Api();
