import Api from '@/helpers/api';
import { STATUS_200, STATUS_300 } from '@/constants/api';

export default {
  /*
   * Fetches interest record and commits to store
   */
  async setStudyAreaData({ commit }, slug) {
    const { data, status } = await Api.getInterest(slug);
    if (status >= STATUS_200 && status < STATUS_300 && data) {
      await commit('SET_STUDY_AREA', { data, slug });
    }
  },
};
