import Vue from 'vue';
import { Notifier } from '@airbrake/browser';

export default () => {
  const airbrake = new Notifier({
    projectId: process.env.AIRBRAKE_ID,
    projectKey: process.env.AIRBRAKE_KEY,
  });

  /*
   ** Only run on client-side and only in production mode
   */

  if (process.env.NODE_ENV !== 'production') {
    return;
  }

  Vue.config.errorHandler = (err, vm, info) => {
    airbrake.notify({
      error: err,
      params: { info },
    });
  };
};
