export default {
  CLOSE_MODAL(state) {
    state.profile.open = false;
    return state;
  },
  OPEN_MODAL(state) {
    state.profile.open = true;
    return state;
  },
  UPDATE_PROFILE(state, payload) {
    state.profile = {
      ...state.profile,
      ...payload,
    };
    return state;
  },
  SET_BREADCRUMBS(state, payload) {
    state.breadcrumbs = payload;
    return state;
  },
};
