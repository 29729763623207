export default {
  offering(state) {
    return state.offering;
  },
  entryRequirements(state) {
    return state.entryRequirements;
  },
  hasAtarQualification(state, getters, rootState, rootGetters) {
    const { residency } = rootGetters.studentType;

    return (qualificationCode) => {
      if (residency === 'international') {
        return false;
      }
      // These are qualifications which have an ATAR, titles are just for reference to their codes
      const qualificationsWithAtar = {
        76: 'ACT Certificate of Education',
        77: 'NSW Higher School Certificate (HSC)',
        80: 'NT Certificate of Education',
        157: 'Queensland Certificate of Education',
        75: 'Victorian Certificate of Education (VCE)',
        81: 'Tasmania Certificate of Education',
        82: 'WA Certificate of Education',
        98: 'International Baccalaureate Diploma',
      };

      return qualificationCode in qualificationsWithAtar;
    };
  },
};
