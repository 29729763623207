/* eslint-disable */

export default ({ app, nuxtState, route }) => {
  /*
   ** Only run on client-side and only in production mode
   */
  if (process.env.NODE_ENV !== 'production') return;

  app.router.afterEach((to, from) => {
    /*
     ** We tell Google Analytics to add a `pageview`
     */
    if (process.browser && window && window.utag_data) {
      if (
        route &&
        route.meta[0].pagetype &&
        route.meta[0].pagetype === 'course'
      ) {
        const course = nuxtState.state.courses.byCode[to.params.slug];
        if (course && course.owning_org_unit) {
          window.utag_data = window.utag_data || {};
          window.utag_data.faculty = course.owning_org_unit;
        }
      }
    }
  });
};
