const sortByTitle = (a, b) => {
  const aTitle = a.title;
  const bTitle = b.title;

  if (aTitle.trim() > bTitle.trim()) {
    return 1;
  }
  if (aTitle.trim() < bTitle.trim()) {
    return -1; // eslint-disable-line no-magic-numbers
  }
  return 0; // eslint-disable-line no-magic-numbers
};

export default sortByTitle;
