import Api from '~/helpers/api';
import { STATUS_200, STATUS_300 } from '~/constants/api';

export default {
  /*
   * Fetches microcredentials record and commits to store
   */

  async setMicrocredentialData({ commit }, slug) {
    const { data, status } = await Api.getMicroCredentials(slug);
    if (status >= STATUS_200 && status < STATUS_300 && data) {
      await commit('SET_MICROCREDENTIAL', { slug, data });
    }
  },
};
