// Wrap iframe el in user managed content
export const wrapIframeEmbeds = (cx) => {
  if (!cx) {
    return;
  }
  const recs = cx.querySelectorAll('iframe');

  if (recs) {
    let wrap;
    let parent;

    Array.prototype.slice.call(recs).forEach((el) => {
      // Check if iframe is a video to add the embed class
      if (el.src.includes('youtube') || el.src.includes('vimeo')) {
        if (
          el.parentElement &&
          el.parentElement.tagName === 'DIV' &&
          el.parentElement.classList.contains('embed')
        ) {
          return;
        }

        wrap = document.createElement('div');
        wrap.classList.add('embed');

        parent = el.parentElement;
        el.before(wrap);
        parent.removeChild(el);

        wrap.appendChild(el);
      }
    });
  }
};

export const formatDollars = (value) => {
  const startFromCharIndex = 0;
  const val = value;

  if (value.includes('-') || value.charAt(startFromCharIndex) === '$') {
    return value;
  }

  const int = parseInt(val, 10);

  if (!Number.isNaN(int)) {
    return `$${int.toLocaleString().toString()}`;
  }

  return `$${value}`;
};

export const sluggify = (text) =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/\(/g, '-') // Replace ( with -
    .replace(/\)/g, '-') // Replace ) with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const trackAnalytics = (
  category,
  action,
  value,
  resultUrl = '', // eslint-disable-line default-param-last
  synonym,
) => {
  if (window && window.utag && window.utag_data) {
    utag.link({
      ga_EventCategory: category,
      ga_EventAction: action,
      ga_EventLabel: value,
      ga_SearchDestUrl: resultUrl,
      ga_searchSynonym: synonym,
    });
  }
};

export const trackAnalyticsVirtualPage = (pageName) => {
  if (window && window.utag && window.utag_data) {
    utag.view({
      ga_virtual_page: pageName,
    });
  }
};

export const analyticsSetPage = (pageData) => {
  window.uom_analytics = window.uom_analytics || {};
  window.uom_analytics.page = window.uom_analytics.page || {};

  window.uom_analytics.page = {
    ...window.uom_analytics.page,
    ...pageData,
  };
};
export const analyticsTriggerEvent = (eventName, eventData) => {
  window.uom_analytics.event = window.uom_analytics.event || {};
  window.uom_analytics.event = {
    ...window.uom_analytics.event,
    ...eventData,
  };

  const event = new Event(eventName);
  window.dispatchEvent(event);
};

export const isMobile = () => {
  const windowWidth = document.documentElement.clientWidth;
  const desktopMinWidth = 769;

  if (windowWidth < desktopMinWidth) {
    return true;
  }

  return false;
};
