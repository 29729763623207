import Vue from 'vue';
import VueI18n from 'vue-i18n';

import stringsEng from '~/assets/strings/en.json';

Vue.use(VueI18n);

export default ({ app }) => {
  // Set i18n instance on app
  app.i18n = new VueI18n({
    locale: 'en',
    messages: {
      en: stringsEng,
      none: {},
    },
  });
};
