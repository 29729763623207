export default {
  async setBreadcrumbs({ commit }, payload) {
    commit('SET_BREADCRUMBS', payload);
  },

  async updateProfile({ commit }, payload) {
    commit('UPDATE_PROFILE', payload);
  },

  async openModal({ commit }) {
    commit('OPEN_MODAL');
  },

  async closeModal({ commit }) {
    commit('CLOSE_MODAL');
  },
};
