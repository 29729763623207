export const qualifications = [
  {
    id: 'undergrad',
    name: 'undergraduate',
    default: true,
  },
  {
    id: 'postgrad',
    name: 'graduate',
    default: false,
  },
  {
    id: 'non-award',
    name: '',
    default: false,
  },
  {
    id: 'research',
    name: 'research',
    default: false,
  },
];

export const defaultQualification = () => {
  const result = qualifications.filter((element) => element.default === true);
  if (!result.length) {
    return qualifications[0].id;
  }
  return result[0].id;
};
