<template>
  <div
    class="breadcrumbs-bar"
    data-test="breadcrumbs-bar"
    :class="{ 'nested-course-page': nestedCoursePage }"
  >
    <PageBreadcrumbs :items="breadcrumbs" />
  </div>
</template>

<script>
import { PageBreadcrumbs } from '@unimelb/pattern-lib-vue';

export default {
  components: {
    PageBreadcrumbs,
  },
  computed: {
    breadcrumbs() {
      if (
        this.$store.state.breadcrumbs &&
        this.$store.state.breadcrumbs.length &&
        this.$store.state.breadcrumbs.every((el) => !!el.href && !!el.text)
      ) {
        return this.$store.state.breadcrumbs;
      }
      return [{ href: '/', text: ' ' }];
    },
    profileLabel() {
      return `${this.$store.getters.studentType.residency} ${this.$store.getters.studentType.level}`;
    },
    nestedCoursePage() {
      const three = 3;
      return (
        this.$route.path.startsWith('/find/courses/') &&
        this.breadcrumbs.length > three
      );
    },
  },
  methods: {
    openModal() {
      this.$store.dispatch('openModal');
    },
  },
};
</script>

<style lang="postcss">
.breadcrumbs-bar {
  display: inline-flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
  padding-right: 1.75rem;
  background-color: var(--col-bg-frame-100);
  color: var(--col-text-light);

  &__btns {
    display: flex;
  }

  .profile {
    display: flex;
    align-items: center;

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;

      &:hover {
        text-decoration: underline;
      }

      @media (--bp-max-tablet) {
        flex-direction: column;
      }

      &-label {
        @mixin typography-level-7;

        &--long {
          @media (--bp-max-tablet) {
            display: none;
          }
        }

        &--short {
          @media (--bp-desktop) {
            display: none;
          }
        }
      }
    }

    &__icon {
      width: calc(var(--sp-3) * 0.75);
      height: calc(var(--sp-3) * 0.75);
      margin-right: auto;
      margin-left: auto;

      @media (--bp-desktop) {
        margin-right: 0.5rem;
      }
    }
  }
}

.nested-course-page {
  nav {
    @media (--bp-max-tablet) {
      .page-breadcrumbs__item:first-child,
      .page-breadcrumbs__item:nth-last-child(2) {
        display: none;
      }
      .page-breadcrumbs__item:nth-last-child(3) .page-breadcrumbs__chevron {
        display: none;
      }

      .page-breadcrumbs__item:nth-last-child(1):not(:first-child) {
        font-weight: var(--fw-semibold);
      }
      .page-breadcrumbs__item:first-child,
      .page-breadcrumbs__item:nth-last-child(3) {
        display: block;
      }
    }
  }
}
</style>
