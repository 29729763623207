import Api from '@/helpers/api';
import { STATUS_200, STATUS_300 } from '@/constants/api';
import {
  undergradQualification,
  preReqsBySelectedYear,
  featuredList,
  entryReqPageScoresList,
} from '@/helpers/entryrequirements';

export default {
  /*
   * Fetches offering record and commits to store
   */
  async setOfferingData({ commit, dispatch }, slug) {
    const { data, status } = await Api.getOffering(slug.toLowerCase());
    if (status >= STATUS_200 && status < STATUS_300 && data) {
      await commit('SET_OFFERING', { data });
      await dispatch('setEntryRequirements');
    }
  },

  /*
   * Fetches Entry Requirements data from offering record,
   * Fetches data from user profile,
   * Commits the fees to the store according to the profile data
   */
  async setEntryRequirements({ commit, getters, rootGetters }) {
    // Offering data
    const { offering } = getters;

    // Data from users
    const { qualificationCode, year } = rootGetters.getProfile;
    const { residency } = rootGetters.studentType;

    let payloadToSave = null;

    if (
      offering.offering_level === 'undergraduate' &&
      offering.entry_requirements &&
      offering.entry_requirements.entry_scores
    ) {
      // Find qualification according to profile (residence and qualification code)
      const selectedQualification = undergradQualification({
        offering,
        residency,
        qualificationCode,
      });

      // Create curated entry scores list (featuredList) to be featured in the AtAGlance box
      const selectedQualificationCode =
        selectedQualification.qualification_code;

      let isAtar = false;

      if (selectedQualificationCode) {
        isAtar = getters.hasAtarQualification(selectedQualificationCode);
      }

      // Pull list of scores listed for the selected qualification
      const scoresList = selectedQualification.scores;

      // The original payload stays intact, we just add fields targeted to the user profile
      payloadToSave = {
        ...offering.entry_requirements,
        byProfile: {
          entryScores: selectedQualification,
          featuredScores: featuredList({ scoresList, isAtar }),
          preRequisites: preReqsBySelectedYear({ selectedQualification, year }),
          scoresList: entryReqPageScoresList({ scoresList }),
        },
      };
    } else {
      payloadToSave = {
        ...offering.entry_requirements,
      };
    }

    await commit('SET_ENTRY_REQUIREMENTS', { ...payloadToSave });
  },
};
