import { defaultQualification } from '@/constants/qualification';
import { defaultResidency } from '@/constants/residency';
import entryReqs from '~/static/data/entryReqs.json';

const DISABLE_SAMPLE_PLANS = process.env.DISABLE_SAMPLE_PLANS === 'true';
const ACTIVE_YEAR = entryReqs.years.filter((year) => year.active);
const DEFAULT_QUALIFICATION = defaultQualification();
const DEFAULT_RESIDENCY = defaultResidency();

export default () => ({
  breadcrumbs: [],
  profile: {
    open: false,
    loggedin: false,
    qualification: DEFAULT_QUALIFICATION,
    qualificationCode: 75,
    residency: DEFAULT_RESIDENCY,
    persona: 'secondary',
    method: 'default',
    year: ACTIVE_YEAR[0].title,
  },
  featureFlags: {
    disableSamplePlans: DISABLE_SAMPLE_PLANS,
    entryreqs: true,
    activeYear: ACTIVE_YEAR[0].title,
  },
});
