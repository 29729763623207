const mapCourse = (course) => {
  let type = String(course.category).toLowerCase();

  // Type fixes for course cards
  if (type === 'concurrent diploma') {
    type = 'diploma';
  } else if (course.offering_type === 'short-course') {
    type = 'short-course';
  } else if (course.offering_type === 'microcredential') {
    type = 'short-course';
  }

  return {
    ...course,
    type,
  };
};

export default mapCourse;
