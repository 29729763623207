import Vue from 'vue';

let firstMounted = false;

// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted(el, conds) {
    const always = conds.value ? conds.value.always : false;

    // Skip focus on page load
    if (!firstMounted && !always) {
      firstMounted = true;
      return;
    }

    // Make sure the element is focussable
    if (!['a', 'button', 'input'].includes(el.tagName.toLowerCase())) {
      el.setAttribute('tabindex', '-1');
    }

    // Focus the element
    el.focus();
  },
});
