export default {
  SET_OFFERING(state, payload) {
    state.offering = payload.data;
    return state;
  },
  SET_ENTRY_REQUIREMENTS(state, payload) {
    state.entryRequirements = payload;
    return state;
  },
};
