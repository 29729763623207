import Vue from 'vue';

export const capitalize = function capitalize(value) {
  const startFromCharIndex = 0;
  const sliceFromIndex = 1;

  if (!value) {
    return '';
  }
  const newValue = value.toString().trim();
  return (
    newValue.charAt(startFromCharIndex).toUpperCase() +
    newValue.slice(sliceFromIndex)
  );
};

export const truncate = function truncate(text, stop, clamp) {
  const startFromCharIndex = 0;

  return (
    text.slice(startFromCharIndex, stop) +
    (stop < text.length ? clamp || '...' : '')
  );
};

/* eslint-disable no-param-reassign */
export const decodeEntities = function decodeHTMLEntities(str) {
  if (document) {
    const element = document.createElement('div');
    if (str && typeof str === 'string') {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
  }
  return str;
};
/* eslint-enable no-param-reassign */

Vue.filter('capitalize', capitalize);
Vue.filter('truncate', truncate);
Vue.filter('decodeEntities', decodeEntities);
