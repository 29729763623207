import { get } from '@/helpers/get';

const messages = require('~/assets/strings/en.json');

// Find entry requirements values according to what is set in user profile passed as params
export function undergradQualification(params) {
  const scoresList =
    params.offering.entry_requirements.entry_scores[params.residency];

  return scoresList.find(
    (item) =>
      parseInt(item.qualification_code, 10) ===
      parseInt(params.qualificationCode, 10),
  );
}

// Correct pre-requisite according to the year set in user profile
export function preReqsBySelectedYear(params) {
  const preReqList = params.selectedQualification.prerequisites_by_year;

  return preReqList.find(
    (item) => parseInt(item.year, 10) === parseInt(params.year, 10),
  );
}

// Function to assert if given title should be displayed as ATAR
function filteredAtarToDisplay(title) {
  const atarListToDisplay = ['lowest selection rank', 'access melbourne'];

  const matchingItems = atarListToDisplay.filter((atarTitle) =>
    title.includes(atarTitle),
  );

  return !!matchingItems.length;
}

// List of entry scores to be featured in the "At a glance" section
export const featuredList = (params) => {
  // Create curated entry scores list
  let featuredListOfScores;

  if (params.scoresList && params.scoresList.length) {
    featuredListOfScores = params.scoresList
      .filter(
        (e) => !params.isAtar || filteredAtarToDisplay(e.heading.toLowerCase()),
      )
      .map((e) => {
        if (e.heading.toLowerCase().includes('access melbourne')) {
          return {
            heading: 'Special entry and access available',
            isAtar: true,
          };
        }
        return {
          ...e,
          isAtar: params.isAtar,
        };
      });
  } else {
    featuredListOfScores = [];
  }

  return featuredListOfScores;
};

export function entryReqPageScoresList(params) {
  const { scoresList } = params;

  const isMatch = (a, b) => a.toLowerCase().includes(b.toLowerCase());

  const promoted = scoresList.find((req) => {
    if (isMatch(req.heading, 'Entry score')) {
      return true;
    }

    if (isMatch(req.heading, 'Lowest Selection Rank')) {
      return true;
    }

    return false;
  });

  if (promoted) {
    promoted.promoted = true;
    return [
      promoted,
      ...scoresList
        .filter((e) => e.heading !== promoted.heading)
        .map((e) => {
          if (e.heading.toLowerCase().includes('access melbourne')) {
            if (e.subheading === 'Guaranteed entry') {
              return {
                ...e,
                guarantee: true,
                subheading: e.subheading,
                heading: e.heading,
              };
            }
            return {
              ...e,
              score: '',
              subheading: '',
              heading: get(messages, [
                'component',
                'entry-reqs',
                'access-melbourne',
                'non-guarantee',
                'heading',
              ]),
            };
          }
          return {
            ...e,
          };
        }),
    ];
  }
  return scoresList;
}

export default {
  undergradQualification,
  preReqsBySelectedYear,
  featuredList,
  entryReqPageScoresList,
};
