const TIMER_15 = 15;
const TIMER_25 = 25;
const TIMER_100 = 100;
const TIMER_300 = 300;
const TIMER_500 = 500;
const TIMER_1000 = 1000;
const TIMER_1500 = 1500;
const TIMER_2000 = 2000;
const TIMER_10000 = 10000;

module.exports = {
  TIMER_15,
  TIMER_25,
  TIMER_100,
  TIMER_300,
  TIMER_500,
  TIMER_1000,
  TIMER_1500,
  TIMER_2000,
  TIMER_10000,
};
