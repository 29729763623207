import Api from '~/modules/study-index/core/api';
import sortByTitle from '~/modules/study-index/helpers/sortByTitle';
import mapCourse from '~/modules/study-index/helpers/mapCourse';
import { STATUS_200 } from '~/constants/api';

export default ({ store }, inject) => {
  const options = {"api":"https:\u002F\u002Ffindacourse.study.unimelb.edu.au\u002Ffind\u002Findex-api"}

  const api = new Api(options);

  inject('studyIndex', { options, api });

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  // Register Vuex module
  if (store) {
    const storeModule = {
      namespaced: true,
      state: () => ({
        interests: [],
        courses: [],
        allCourses: [],
        majors: [],
        activeStudyArea: null,
        activeOffering: null,
        studyModeFilters: [],
        facultyFilters: []
      }),
      mutations: {
        setStudyAreas (state, payload) {
          state.interests = payload
        },
        setActiveStudyArea (state, payload) {
          state.activeStudyArea = payload
        },
        setCourses (state, payload) {
          state.courses = payload
        },
        setActiveOffering (state, payload) {
          state.activeOffering = payload
        },
        setMajors (state, payload) {
          state.majors = payload
        },
        setAllCourses (state, payload) {
          state.allCourses = payload
        },
        setStudyModeFilters (state, payload) {
          state.studyModeFilters = payload
        },
        setFacultyFilters (state, payload) {
          state.facultyFilters = payload
        },
      },
      actions: {
        async fetchStudyAreas({ commit }) {
          const { data, status } = await api.getAllInterests();
          if (status === STATUS_200) {
            commit('setStudyAreas', data);
          }
        },
        async fetchStudyArea({ commit }, slug) {
          try {
            const { data, status } = await api.getInterest(slug);
            if (status === STATUS_200) {
              // Map concurrent dimplomas as diplomas, sort courses
              // TODO - Move category logic to CardCourse - refer to `type` property for color
              // TODO - ensure courses are sorted in static api, delete line
              if (data.related_courses && data.related_courses.length) {
                data.related_courses = data.related_courses.map(course => ({
                  ...course,
                  category: course.category === 'concurrent diploma' ? 'diploma' : course.category
                })).sort(sortByTitle);
              }

              commit('setActiveStudyArea', data);
            } else {
              throw new Error(`Fetching offering ${slug} resulted in ${status}`)
            }
          } catch (err) {
            throw err
          }
        },
        clearActiveStudyArea({ commit }) {
          commit('setActiveStudyArea', null);
        },
        async fetchCourses({ commit }) {
          const responses = await Promise.all([
            api.getAllCourses(),
            api.getAllShortCourses()
          ])

          const courses = []

          responses.forEach((response) => {
            if (response.status === STATUS_200) {
              courses.push(...response.data)
            }
          })

          commit('setCourses', courses.sort(sortByTitle).map(mapCourse)) // Merge courses + short courses
          commit('setAllCourses', courses.sort(sortByTitle).map(mapCourse))

          try {
            // Get filters
            let studyModes = [];
            let faculties = [];

            courses.forEach((c) => {
              const studyMode = c.filters && c.filters.studyMode ? c.filters.studyMode : [];
              const faculty = c.filters && c.filters.faculties ? c.filters.faculties : (c.filters.faculty || false);

              if (Array.isArray(studyMode)) {
                studyModes.push(...studyMode);
              } else {
                studyModes.push(studyMode);
              }

              if (Array.isArray(faculty)) {
                faculties.push(...faculty);
              } else {
                faculties.push(faculty);
              }
            });

            studyModes = studyModes.filter(onlyUnique);
            faculties = faculties.filter(onlyUnique).filter(Boolean).sort();
            commit('setStudyModeFilters', studyModes);
            commit('setFacultyFilters', faculties);
          } catch (err) {
            console.warn(err) // eslint-disable-line no-console
          }
        },
        async fetchOffering({ commit }, { slug, type = '' }) {
          try {
            const { data, status } = await api.getOffering(slug, type)
            if (status === STATUS_200) {
              commit('setActiveOffering', data);
            } else {
              throw new Error(`Fetching offering ${slug} resulted in ${status}`)
            }
          } catch (err) {
            throw err
          }
        },
        async fetchShortCourse({ commit }, { slug }) {
          try {
            const { data, status } = await api.getShortCourse(slug)
            if (status === STATUS_200) {
              commit('setActiveOffering', data);
            } else {
              throw new Error(`Fetching offering ${slug} resulted in ${status}`)
            }
          } catch (err) {
            throw err
          }
        },
        clearActiveOffering({ commit }) {
          commit('setActiveOffering', null);
        },
        async fetchMajors({ commit }) {
          const responses = await Promise.all([
            api.getAllComponents(),
            api.getAllHonoursSpecialisation()
          ]);

          const majors = []

          responses.forEach((response) => {
            if (response.status === STATUS_200) {
              majors.push(...response.data)
            }
          })

          commit('setMajors', majors.sort(sortByTitle));
        },
        resetCourses({ commit, state }) {
          commit('setCourses', state.allCourses);
        }
      }
    };

    store.registerModule('studyIndex', storeModule, { preserveState: !!store.state.studyIndex });
  }
};
