// eslint-disable-next-line func-names
export default function (context) {
  if (context.route.name === 'index') {
    context.store.commit('clearHistory');
  } else {
    context.store.commit('logHistory', {
      fullPath: context.route.fullPath,
      name: context.params.slug,
      type: context.route.name,
    });
  }
}
