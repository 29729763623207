import Api from '@/helpers/api';
import { STATUS_200, STATUS_300 } from '@/constants/api';

export default {
  /*
   * Fetches short courses record and commits to store
   */

  async setShortCourseData({ commit }, slug) {
    const { data, status } = await Api.getShortCourses(slug);
    if (status >= STATUS_200 && status < STATUS_300 && data) {
      await commit('SET_SHORT_COURSE', { slug, data });
    }
  },
};
